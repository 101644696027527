export interface IOrderSlice {
  inputAmount: string,
  inputValue: string,
  outputAmount: string,
  outputValue: string,
  step: number,
  inputSelect: string[],
  outputSelect: string[],
}

type InputValue = {
  type: "type/CHANGEINPUTVALUE";
  payload: string;
};

type OutputValue = {
  type: "type/CHANGEOUTPUTVALUE";
  payload: string;
};

type InputAmount = {
  type: "type/CHANGEINPUTAMOUNT";
  payload: string;
};

type OutputAmount = {
  type: "type/CHANGEOUTPUTAMOUNT";
  payload: string;
};

type Step = {
  type: "type/CHANGESTEP";
  payload: string;
};

type InputSelect = {
  type: "type/CHANGEINPUTSELECT";
  payload: string[];
};

type OutputSelect = {
  type: "type/CHANGEOUTPUTSELECT";
  payload: string[];
};

type OrderAction =
  InputValue |
  OutputValue |
  InputAmount |
  OutputAmount |
  Step |
  InputSelect |
  OutputSelect;

const setInputValue = (select: string) => {
  return {
    type: "type/CHANGEINPUTVALUE",
    payload: select,
  };
};

const setOutputValue = (value: string) => {
  return {
    type: "type/CHANGEOUTPUTVALUE",
    payload: value,
  };
};

const setInputAmount = (select: string) => {
  return {
    type: "type/CHANGEINPUTAMOUNT",
    payload: select,
  };
};

const setOutputAmount = (value: string) => {
  return {
    type: "type/CHANGEOUTPUTAMOUNT",
    payload: value,
  };
};

const setStep = (value: number) => {
  return {
    type: "type/CHANGESTEP",
    payload: value,
  };
};

const setInputSelect = (value: string[]) => {
  return {
    type: "type/CHANGEINPUTSELECT",
    payload: value,
  };
};

const setOutputSelect = (value: string[]) => {
  return {
    type: "type/CHANGEOUTPUTSELECT",
    payload: value,
  };
};

export const orderActions = {
  setInputAmount,
  setInputValue,
  setOutputAmount,
  setOutputValue,
  setStep,
  setInputSelect,
  setOutputSelect,
};

const initialSate = {
  inputAmount: "50",
  inputValue: "EUR",
  outputAmount: "",
  outputValue: "CA",
  step: 1,
  inputSelect: ["EUR"],
  outputSelect: ["CA", "BTC", "ETH", "LTC", "XRP", "BCH"],
};

const orderReducer = (state: IOrderSlice = initialSate, action: OrderAction) => {
  switch (action.type) {
    case "type/CHANGEINPUTVALUE":
      return { ...state, inputValue: action.payload };
    case "type/CHANGEINPUTAMOUNT":
      return { ...state, inputAmount: action.payload };
    case "type/CHANGEOUTPUTVALUE":
      return { ...state, outputValue: action.payload };
    case "type/CHANGEOUTPUTAMOUNT":
      return { ...state, outputAmount: action.payload };
    case "type/CHANGESTEP":
      return { ...state, step: action.payload };
    case "type/CHANGEINPUTSELECT":
      return { ...state, inputSelect: action.payload };
    case "type/CHANGEOUTPUTSELECT":
      return { ...state, outputSelect: action.payload };
    default:
      return state;
  }
}; 

export default orderReducer;
