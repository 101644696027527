import React, { useState, useEffect } from "react";
import logo from "../images/logo-modal-flashlly.png";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store/store";
import { orderActions } from "../redux/slices/order.slice";
import { useTranslation } from "react-i18next";
import { Dropdown } from "react-bootstrap";

export const Step3 = () => {
  const inputAmount = useSelector(({ order }: RootState) => order.inputAmount);
  const inputValue = useSelector(({ order }: RootState) => order.inputValue);
  const inputSelect = useSelector(({ order }: RootState) => order.inputSelect);

  const i18nextLng = localStorage.getItem("i18nextLng");

  const [max1M, setMax1M] = useState(false);

  // const outputAmount = useSelector(
  //   ({ order }: RootState) => order.outputAmount
  // );
  // const outputValue = useSelector(({ order }: RootState) => order.outputValue);
  // const outputSelect = useSelector(
  //   ({ order }: RootState) => order.outputSelect
  // );

  const [isScreenBig, setIsScreenBig] = useState(true);

  useEffect(() => {
    if (window.innerHeight < 550) {
      setIsScreenBig(false);
    }
  }, []);

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const handleChangeAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Put new digits to the input with amount
    const { value } = event.target;

    if (Number(value) > 999999.99) {
      setMax1M(true);
    } else setMax1M(false);

    if (!isNaN(Number(value))) {
      dispatch(orderActions.setInputAmount(value));
    }
  };

  const handleDevisInputValue = (currency: string) => {
    dispatch(orderActions.setInputValue(currency));
  };

  // const handleDevisOutputValue = (currency: string) => {
  //   dispatch(orderActions.setOutputValue(currency));
  // };

  return (
    <>
      <div className="col d-flex">
        <img
          src={logo}
          width={160}
          height={40}
          className="my-2 mx-auto"
          alt="logoFlashlly"
        />
      </div>

      <div>
        <p className="fw-bold p-0">{t("change.input")}</p>

        <div className="row border border-dark rounded d-flex p-0 m-0 mb-5">
          <input
            value={inputAmount}
            type="text"
            className="p-3 border-0 bg-transparent fw-bold fs-4 col-9"
            onChange={(event) => handleChangeAmount(event)}
            height="100%"
          />
          <div className="col-3 d-flex justify-content-center align-items-center fw-bold">
            EUR
          </div>

          {/* <Dropdown className="d-flex flex-row col-3 p-2">
            <Dropdown.Toggle
              className="w-100 d-flex justify-content-center align-items-center p-2"
              style={{
                backgroundColor: "#FFF",
                border: "none",
                color: "black",
              }}
            >
              <div className="p-0">{inputValue}</div>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {inputSelect?.map((dev, index) => (
                <Dropdown.Item
                  key={index}
                  onClick={() => handleDevisInputValue(dev)}
                  eventKey={index + 1}
                >
                  {dev}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown> */}
        </div>
        <div>
          <div className="text-center mt-3" style={{ fontSize: "10px" }}>
            {i18nextLng === "fr"
              ? `Votre achat de ${inputAmount} ${inputValue} est spécifiquement destiné à l'acquisition de ${inputAmount} UTG.`
              : `Your purchase of ${inputAmount} ${inputValue} is specifically intended for the acquisition of ${inputAmount} UTG.`}
          </div>
          <div className="text-center mt-3" style={{ fontSize: "10px" }}>
            {i18nextLng === "fr"
              ? `Cette cryptomonnaie est acceptée par le casino pour une valeur de un pour un.`
              : `This cryptocurrency is accepted by the casino at a one-to-one value.`}
          </div>
        </div>
        <br />

        {max1M && (
          <div className="text-center text-danger">{`${t(
            "change.message"
          )} 999,999.99 ${inputValue}`}</div>
        )}
        {
          isScreenBig && (
            <>
              <p className="fw-bold p-0">{t("change.output")}</p>
              <div className="row border border-dark rounded d-flex p-0 m-0 ">
                <input
                  value={inputAmount}
                  type="text"
                  className="p-3 border-0 bg-transparent fw-bold fs-4 col-9"
                  onChange={(event) => handleChangeAmount(event)}
                  height="100%"
                  readOnly
                />
                <div className="col-3 d-flex justify-content-center align-items-center fw-bold">
                  UTG
                </div>

              </div>
            </>
          )
        }






      </div>

      <div className="row w-100 p-0 m-0">
        <div className="p-0 px-2 w-100">
          <button
            className="btn w-100 p-3"
            style={{
              backgroundColor: "#6E40C9",
              color: "#FFF",
            }}
            onClick={() => dispatch(orderActions.setStep(4))}
            disabled={max1M}
          >
            {t("change.button")}
          </button>
        </div>
      </div>
    </>
  );
};
