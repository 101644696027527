import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../redux/store/store";
import { Step1 } from "./Step1";
import { Step3 } from "./Step3";
import { Step2 } from "./Step2";
import Payment from "./Payment";
import { LanguagesBar } from "./LanguageBar";
import { useParams } from "react-router-dom";
import { authActions } from '../redux/slices/auth.slice';

export const Widget: React.FC = () => {

  const step = useSelector(({ order }: RootState) => order.step);

  const dispatch = useDispatch();
  const allParams = useParams();

  React.useEffect(() => {
    if (allParams) {
      if (allParams.firstname) {
        dispatch(authActions.setfirst_name(allParams.firstname));
      }
      if (allParams.lastname) {
        dispatch(authActions.setlast_name(allParams.lastname));
      }
      if (allParams.mail) {
        dispatch(authActions.setmail(allParams.mail));
      }
      // if (allParams.address) {
      //   dispatch(authActions.setaddress(allParams.address));
      // }
      if (allParams.institution) {
        dispatch(authActions.setinstitution(allParams.institution));
      }
      if (allParams.id_institution) {
        dispatch(authActions.setid_institution(allParams.id_institution));
      }
    }
  }, [allParams, dispatch]);

  return (
    <div
      className="d-flex justify-content-center align-items-between"
      style={{
        height: "100vh",
        width: "100vw",
      }}
    >
      <div
        className="row p-4 pt-5 h-100 w-100 d-flex align-content-between position-relative"
        style={{
          height: "100vh",
          maxWidth: "400px",
        }}
      >
        <LanguagesBar
          classStyle={
            "pt-2 pe-5 text-dark drop-language position-absolute d-flex justify-content-end end-0"
          }
        />

        {step === 1 && <Step1 />} 

        {step === 2 && <Step2 />}

        {step === 3 && <Step3 />}

        {step === 4 && <Payment />}
      </div>
    </div>
  );
};
