import { combineReducers } from 'redux';
import orderReducer, { IOrderSlice } from '../slices/order.slice';
import authReducer, { IAuthSlice } from '../slices/auth.slice';
import langIconReducer, { ILangIcon } from '../slices/langauge.slice';

export interface RootState {
  order: IOrderSlice,
  auth: IAuthSlice,
  languages: ILangIcon,
}

const rootReducer = combineReducers({
  order: orderReducer,
  auth: authReducer,
  languages: langIconReducer,
});

export default rootReducer;