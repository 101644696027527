import logo from "../images/logo-modal-flashlly.png";
import React, { useEffect } from "react";

function Completion() {

  console.log("Great success!!")
  useEffect(() => {
    setTimeout(() => {
      window.parent.location.href = 'https://www.madbet.eu'; // Remplacez par l'URL de redirection souhaitée
    }, 2000);
  }, []);

  return (
    <div
      className="border"
      style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}
    >
      <div className="d-flex flex-column align-items-center justify-content-around h-100">
        <div className="text-center">
          <img
            src={logo}
            width={160}
            height={40}
            alt="logoFlashlly"
            className="mx-auto"
          />
        </div>
        <div className="text-center">
          <p className="fw-bold fs-2">Merci !</p>
          <p className="fw-bold fs-2">Votre paiement a été effectué avec succès. </p>
        </div>
      </div>
    </div>
  );
}

export default Completion;
