import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import arrowLeft from "../images/iconArrowLeft.svg";
import { orderActions } from "../redux/slices/order.slice";
import { useTranslation } from "react-i18next";
import { RootState } from "../redux/store/store";
import { authActions } from "../redux/slices/auth.slice";
import logo from "../images/logo-modal-flashlly.png";

interface ErrorMax5Type {
  message: {
    message: string;
    date: string;
    code: string;
    result: string;
  };
}

export const Step2 = () => {
  const phoneNumber = useSelector(({ auth }: RootState) => auth.phoneNumber);
  const pinCode = useSelector(({ auth }: RootState) => auth.codePin);
  const id_external = useSelector(({ auth }: RootState) => auth.id_external);
  const first_name = useSelector(({ auth }: RootState) => auth.first_name);
  const last_name = useSelector(({ auth }: RootState) => auth.last_name);
  const mail = useSelector(({ auth }: RootState) => auth.mail);
  const address = useSelector(({ auth }: RootState) => auth.address);
  const [pinValue, setPinValue] = useState(["", "", "", "", "", ""]);
  const inputRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];
  const [errorPin, setErrorPin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showErrorCode, setShowErrorCode] = useState(false);
  const [showErrorMax5, setShowErrorMax5] = useState(false);
  const [errorMax5, setErrorMax5] = useState<ErrorMax5Type>();
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const handleChange = async (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setErrorPin(false);
    // Put new digits to the input with pin code
    const { value } = event.target;

    if (!isNaN(Number(value)) && value !== "") {
      const newInputValues = [...pinValue];
      newInputValues[index] = value.substring(0, 1);

      setPinValue(newInputValues);

      dispatch(authActions.setCodePin(newInputValues.join("")));

      if (newInputValues.join("").length === 6) {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/sms/SmsCode`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            codeClient: newInputValues.join(""),
            phone: "+" + phoneNumber
          }),
        });

        // if (!response.ok) {
        //   throw new Error("Network response was not ok");
        // }
        await response.json().then((data) => {
          localStorage.setItem("tokenWidget", data.token)

          if (data.data === "OK") {
            dispatch(orderActions.setStep(3));
            return;
          } else if (data?.message === "KO invalid code") {
            setErrorPin(true);
            setShowErrorCode(true);
            return;
          } else if (data?.message?.code === "1578") {
            setShowErrorMax5(true);
            setErrorMax5(data);
            return;
          } else {
            console.log("error: request code sms");
            return;
          }
        });
      }

      if (index < inputRefs.length - 1 && value !== "") {
        //@ts-ignore
        inputRefs[index + 1].current.focus();
      }
    }
  };

  const handleKeyDown = (
    index: number,
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    // Delete digits from the input with pin code
    if (event.key === "Backspace") {
      //@ts-ignore
      index > 0 && inputRefs[index - 1].current.focus();

      const erasePin = [...pinValue];
      erasePin[index] = "";

      setPinValue(erasePin);
      dispatch(authActions.setCodePin(erasePin.join("")));
    }
  };

  const handlerCodePin = (code: string) => {
    if (pinCode.length < 6) {
      return;
    }
  };

  useEffect(() => {
    handlerCodePin("111111"); // Here is the pin code that you need to change
  }, [pinCode]);

  return (
    <>
      <div className="p-0 d-flex align-items-start">
        <button
          className="col-2 border-0 bg-transparent p-0 m-0"
          // onClick={() => dispatch(orderActions.setStep(2))}
        >
          <img src={arrowLeft} width={42} height={44} alt="arrowLeft" />
        </button>

        <p className="fw-bold fs-7 col-7 m-0">
          {t("step3.title")} +{phoneNumber}
        </p>
      </div>

      {isLoading ? (
        <div className="d-flex justify-content-center">
          <div
            className="spinner-border"
            role="status"
            style={{
              width: "6rem",
              height: "6rem",
            }}
          />
        </div>
      ) : (
        <div className="d-flex flex-column">
          <div
            className="col mx-auto d-flex justify-content-between"
            style={{
              flexGrow: 0,
            }}
          >
            {pinValue.map((value, index) => (
              <input
                key={index}
                ref={inputRefs[index]}
                type="text"
                className="input-pin fs-1 text-center m-1 p-0 border-0 border-bottom border-dark"
                value={value}
                onChange={(event) => handleChange(index, event)}
                onKeyDown={(event) => handleKeyDown(index, event)}
                maxLength={1}
                style={{
                  caretColor: "transparent",
                  height: "50px",
                  width: "50px",
                }}
              />
            ))}
          </div>

          {showErrorCode && (
            <div className="text-danger m-auto pt-3 fs-5">{t("step3.InvalidCode")}</div>
          )}

          {showErrorMax5 && (
            <div className="pt-3 text-center">
              <div className="text-danger fs-5">{errorMax5?.message?.message}</div>
              <div className="text-black">{errorMax5?.message?.date}</div>
            </div>
          )}

          {/* <div className="row d-flex justify-content-center m-0 mt-4 p-0">
            {t("step3.description")}
          </div> */}

          {errorPin && (
            <button
              className="btn btn-dark rounded-pill py-3 col-8 mt-5 mx-auto"
              style={{
                transition: "all 0.1s",
              }}
              onClick={() => {
                setErrorPin(false);
                setShowErrorCode(false);
                setPinValue(["", "", "", "", "", ""]);
                dispatch(authActions.setCodePin(""));
              }}
            >
              {t("step3.sendAgainPin")}
            </button>
          )}
        </div>
      )}

      <div className="d-flex fustify content-center align-items-end mb-2">
        <img
          src={logo}
          width={160}
          height={40}
          className="mx-auto"
          alt="logoFlashlly"
        />
      </div>
    </>
  );
};
