import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const availableLanguages = ['fr','en', 'pl', 'es', 'al', 'it', 'ho', 'in', 'ko', 'tu', 'ch', 'si', 'ja', 'th', 'pt', 'ka', 'cg', 'ar'];
const options = {
  // order and from where user language should be detected
  order: [ 'navigator', 'htmlTag', 'path', 'subdomain'],

  // keys or params to lookup language from
  lookupQuerystring: 'lng',
  lookupCookie: 'i18next',
  lookupLocalStorage: 'i18nextLng',
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,

  // cache user language on
  caches: ['localStorage', 'cookie'],
  excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

  // optional expire and domain for set cookie
  cookieMinutes: 10,
  cookieDomain: 'myDomain',

  // optional htmlTag with lang attribute, the default is:
  htmlTag: document.documentElement,

  // only detect languages that are in the whitelist
  checkWhitelist: true
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "fr",
    debug: true,
    whitelist: availableLanguages,
    detection: options,
    lng: "fr",
    resources: {
      fr: {
        translations: require("./locales/fr/translations.json"),
      },
      en: {
        translations: require("./locales/en/translations.json"),
      },
      pl: {
        translations: require("./locales/pl/translations.json"),
      },
      es: {
        translations: require("./locales/es/translations.json"),
      },
      al: {
        translations: require("./locales/al/translations.json"),
      },
      it: {
        translations: require("./locales/it/translations.json"),
      },
      ho: {
        translations: require("./locales/ho/translations.json"),
      },
      in: {
        translations: require("./locales/in/translations.json"),
      },
      ko: {
        translations: require("./locales/ko/translations.json"),
      },
      tu: {
        translations: require("./locales/tu/translations.json"),
      },
      ch: {
        translations: require("./locales/ch/translations.json"),
      },
      si: {
        translations: require("./locales/si/translations.json"),
      },
      ja: {
        translations: require("./locales/ja/translations.json"),
      },
      th: {
        translations: require("./locales/th/translations.json"),
      },
      pt: {
        translations: require("./locales/pt/translations.json"),
      },
      ka: {
        translations: require("./locales/ka/translations.json"),
      },
      cg: {
        translations: require("./locales/cg/translations.json"),
      },
      ar: {
        translations: require("./locales/ar/translations.json"),
      }
    },
    ns: ["translations"],
    defaultNS: "translations",
  });

i18n.languages = ['en','fr', 'pl', 'es', 'al', 'it', 'ho', 'in', 'ko', 'tu', 'ch', 'si', 'ja', 'th', 'pt', 'ka', 'cg', 'ar'];

export default i18n;

