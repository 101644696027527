import React, { useState } from "react";
import arrowLeft from "../images/iconArrowLeft.svg";
import { useDispatch, useSelector } from "react-redux";
import { orderActions } from "../redux/slices/order.slice";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { authActions } from "../redux/slices/auth.slice";
import logo from "../images/logo-modal-flashlly.png";
import { RootState } from "../redux/store/store";

export const Step1 = () => {
  const [errorPhone, setErrorPhone] = useState(false);
  const [loader, setLoader] = useState(false);
  const [clientSecret, setClientSecret] = useState("");

  const codePin = useSelector(({ auth }: RootState) => auth.codePin);
  const id_external = useSelector(({ auth }: RootState) => auth.id_external);
  const phoneNumber = useSelector(({ auth }: RootState) => auth.phoneNumber);
  const first_name = useSelector(({ auth }: RootState) => auth.first_name);
  const last_name = useSelector(({ auth }: RootState) => auth.last_name);
  const mail = useSelector(({ auth }: RootState) => auth.mail);
  const address = useSelector(({ auth }: RootState) => auth.address);
  const institution = useSelector(({ auth }: RootState) => auth.institution);
  const id_institution = useSelector(({ auth }: RootState) => auth.id_institution);

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const handlePhoneChange = (formattedValue: string) => {
    setErrorPhone(false);
    dispatch(authActions.setPhoneNumber(formattedValue));
  };

  const chandleSubmit = () => {
    setLoader(true);
   // let paymentMethod = 'giropay'; // ou 'eps' ou 'bancontact'
    // if (phoneNumber.length >= 10) {
    let bodyFetch = {
      phone: "+" + phoneNumber,
      first_name: first_name,
      last_name: last_name,
      mail: mail,
      institution,
      id_institution,
    //  paymentMethod: [paymentMethod],
    }
    console.log("bodyFetch",bodyFetch)
    fetch(`${process.env.REACT_APP_API_URL}/sms`,  {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(bodyFetch),
    })
      .then(async (request) => {
        if (request?.status === 400) {
          setErrorPhone(true);
          setLoader(false);
        } else {
          const { clientSecret } = await request.json();
          // console.log(clientSecret, "clientSecret")
          setClientSecret(clientSecret);
          dispatch(orderActions.setStep(2));
        }
      })
      .catch((err) => {
        setErrorPhone(true);
        setLoader(false);
      });
    setErrorPhone(false);
  };
  return (
    <>
      <div className="col p-0 d-flex align-items-center">
        <button
          className="col-2 border-0 bg-transparent p-0"
          onClick={() => dispatch(orderActions.setStep(1))}
        >
          <img src={arrowLeft} width={42} height={44} alt="arrowLeft" />
        </button>

        <p className="fw-bold fs-7 col-7 m-0">{t("step2.title")}</p>
      </div>

      <div className="p-0">
        <p
          className="p-0"
          style={{
            fontSize: "0.7rem",
          }}
        >
          {t("step2.input")}
        </p>

        <div className="p-3 border-bottom border-dark">
          <PhoneInput
            country={"fr"}
            excludeCountries={["us", "gb"]}
            value={""}
            onChange={handlePhoneChange}
            inputStyle={{
              width: "100%",
              border: "none",
              fontSize: "1.2rem",
            }}
            onKeyDown={(event) => {
              console.log(event.key)
              if (event.key === 'Enter') {
                chandleSubmit();
              }
            }}
          />
        </div>

        {errorPhone && (
          <div className="text-danger mt-2">{t("step2.phoneError")}</div>
        )}

        <div className="row w-100 p-0 m-0">
          <div className="col p-0 px-2 w-100">
            <button
              className="btn w-100 p-3 mt-4"
              style={{
                backgroundColor: "#6E40C9",
                color: "#FFF",
              }}
              onClick={chandleSubmit}
            >
              {loader ? (
                <div className="spinner-border text-light" role="status">
                  <span className="sr-only"></span>
                </div>
              ) : (
                <>{t("step2.button")}</>
              )}
            </button>
          </div>
        </div>

        <p className="p-4 fs-6 text-center">{t("step2.description")}</p>
      </div>

      <div className="d-flex fustify content-center align-items-end mb-2">
        <img
          src={logo}
          width={160}
          height={40}
          alt="logoFlashlly"
          className="mx-auto"
        />
      </div>
    </>
  );
};
