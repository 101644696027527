import React from "react";
import "./App.scss";
import { Widget } from "./components/Widget";
//@ts-ignore
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Completion from "./components/Completion";
// add commments
function App() {
  console.log("version 3.2 fr")
  return (
    <main className="App d-flex justify-content-center align-items-center">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Widget />} />
          <Route path="/:firstname/:lastname/:mail/:institution/:id_institution" element={<Widget />} />
          <Route path="/completion" element={<Completion />} />
        </Routes>
      </BrowserRouter>
    </main>
  );
}

export default App;
