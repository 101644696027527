import { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";
import { Elements } from "@stripe/react-stripe-js";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store/store";
import { useTranslation } from "react-i18next";
import { set } from "react-hook-form";


const stripeKey = process.env.REACT_APP_PK_STRIPE;

if (!stripeKey) {
  throw new Error("Stripe key is not defined in environment variables");
}

const stripePromise = loadStripe(stripeKey);

function Payment() {
  const [clientSecret, setClientSecret] = useState("");
  const [loader, setLoader] = useState(true);
  const [errorMessage, setErrorMessage] = useState(false);

  const inputAmount = useSelector(({ order }: RootState) => order.inputAmount);
  const inputValue = useSelector(({ order }: RootState) => order.inputValue);
  const PhoneNumber = useSelector(({ auth }: RootState) => auth.phoneNumber);
  const first_name = useSelector(({ auth }: RootState) => auth.first_name);
  const last_name = useSelector(({ auth }: RootState) => auth.last_name);
  const mail = useSelector(({ auth }: RootState) => auth.mail);
  const address = useSelector(({ auth }: RootState) => auth.address);
  const institution = useSelector(({ auth }: RootState) => auth.institution);
  const id_institution = useSelector(({ auth }: RootState) => auth.id_institution);

  const { t } = useTranslation();

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/stripe/create-payment-intent`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("tokenWidget")}`
      },
      body: JSON.stringify({
        amount: inputAmount,
        currency: inputValue,
        phoneClient: "+" + PhoneNumber,
        first_name: first_name,
        last_name: last_name,
        mail: mail,
        address: address,
        institution: institution,
        id_institution: id_institution
      }),
    }).then(async (request) => {
      const { clientSecret } = await request.json();
      setClientSecret(clientSecret);
      setLoader(false);
    }) 
    .catch((error) => {
      setErrorMessage(true);
      setLoader(false);
    });;
  }, []);

  return (
    <div className="d-flex flex-column align-items-between justify-content-center h-100">
      <div className="d-flex flex-column mt-4 mb-5">
        <div className="d-flex p-2 border border-dark rounded d-flex justify-content-center align-items-center mb-2 text-flashlly">
          <p className="p-0 m-0 fw-bold fs-3">
            {(+inputAmount).toFixed(2)} {inputValue}
          </p>
        </div>
      </div>
      {loader && (
        <div className="text-center">
          <div className="spinner-border text-dark" role="status">
            <span className="sr-only"></span>
          </div>
        </div>
      )}
      {errorMessage && <div className="text-danger text-center">'An error occurred'</div>}
      <div>
        {stripePromise && clientSecret && (
          <Elements stripe={stripePromise} options={{ clientSecret }}>
            <CheckoutForm />
          </Elements>
        )}
      </div>
    </div>
  );
}

export default Payment;
