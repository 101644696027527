export interface ILangIcon {
  lngIcon: string,
  lngValue: string,
}

type LanguageIcon = {
  type: "type/CHANGEICON";
  payload: string;
};

type LanguageValue = {
  type: "type/CHANGELANG";
  payload: string;
};

type LangAction = LanguageIcon | LanguageValue

const setLangIcon = (select: string) => {
  return {
    type: "type/CHANGEICON",
    payload: select,
  };
};

const setLangValue = (value: string) => {
  return {
    type: "type/CHANGELANG",
    payload: value,
  };
};

export const langActions = {
  setLangIcon,
  setLangValue,
};

const initialSate = {
  lngIcon: "https://cdn.countryflags.com/thumbs/france/flag-3d-round-250.png",
  lngValue: "fr",
};

const langIconReducer = (state: ILangIcon = initialSate, action: LangAction) => {
  switch (action.type) {
    case "type/CHANGEICON":
      return { ...state, lngIcon: action.payload };
    case "type/CHANGELANG":
      return { ...state, lngValue: action.payload };
    default:
      return state;
  }
};

export default langIconReducer;
