export interface IAuthSlice {
  phoneNumber: string;
  codePin: string;
  id_external: string;
  first_name: string;
  last_name: string;
  mail: string;
  address: string;
  institution: string;
  id_institution: string;
}

type PhoneNumber = {
  type: "type/CHANGEPHONENUMBER";
  payload: string;
};

type CodePin = {
  type: "type/CHANGECODEPIN";
  payload: string;
};

type institution = {
  type: "type/CHANGEINSTITUTION";
  payload: string;
};

type id_institution = {
  type: "type/CHANGEID_INSTITUTION";
  payload: string;
};

type id_external = {
  type: "type/CHANGEIDEXTERNAL";
  payload: string;
};

type first_name = {
  type: "type/CHANGENAME";
  payload: string;
};

type last_name = {
  type: "type/CHANGELASTNAME";
  payload: string;
};

type mail = {
  type: "type/CHANGEMAIL";
  payload: string;
};

type address = {
  type: "type/CHANGEADDRESS";
  payload: string;
};

type AuthAction =
  | PhoneNumber
  | CodePin
  | id_external
  | first_name
  | last_name
  | mail
  | address
  | institution
  | id_institution;

const setPhoneNumber = (select: string) => {
  return {
    type: "type/CHANGEPHONENUMBER",
    payload: select,
  };
};

const setCodePin = (value: string) => {
  return {
    type: "type/CHANGECODEPIN",
    payload: value,
  };
};

const setinstitution = (value: string) => {
  return {
    type: "type/CHANGEINSTITUTION",
    payload: value,
  };
};

const setid_institution = (value: string) => {
  return {
    type: "type/CHANGEID_INSTITUTION",
    payload: value,
  };
};

const setid_external = (value: string) => {
  return {
    type: "type/CHANGEIDEXTERNAL",
    payload: value,
  };
};

const setfirst_name = (value: string) => {
  return {
    type: "type/CHANGENAME",
    payload: value,
  };
};

const setlast_name = (value: string) => {
  return {
    type: "type/CHANGELASTNAME",
    payload: value,
  };
};

const setmail = (value: string) => {
  return {
    type: "type/CHANGEMAIL",
    payload: value,
  };
};

const setaddress = (value: string) => {
  return {
    type: "type/CHANGEADDRESS",
    payload: value,
  };
};

export const authActions = {
  setPhoneNumber,
  setCodePin,
  setid_external,
  setfirst_name,
  setlast_name,
  setmail,
  setaddress,
  setinstitution,
  setid_institution,
};

const initialSate = {
  phoneNumber: "",
  codePin: "",
  id_external: "",
  first_name: "",
  last_name: "",
  mail: "",
  address: "",
  institution: "",
  id_institution: "",
};

const authReducer = (state: IAuthSlice = initialSate, action: AuthAction) => {
  switch (action.type) {
    case "type/CHANGEPHONENUMBER":
      return { ...state, phoneNumber: action.payload };
    case "type/CHANGECODEPIN":
      return { ...state, codePin: action.payload };
    case "type/CHANGEINSTITUTION":
      return { ...state, institution: action.payload };
    case "type/CHANGEID_INSTITUTION":
      return { ...state, id_institution: action.payload };
    case "type/CHANGEIDEXTERNAL":
      return { ...state, id_external: action.payload };
    case "type/CHANGENAME":
      return { ...state, first_name: action.payload };
    case "type/CHANGELASTNAME":
      return { ...state, last_name: action.payload };
    case "type/CHANGEMAIL":
      return { ...state, mail: action.payload };
    case "type/CHANGEADDRESS":
      return { ...state, address: action.payload };
    default:
      return state;
  }
};

export default authReducer;
